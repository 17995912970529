import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import NotFound from "../Pages/Website/NotFound/NotFound";



const Router = () => {
    const SignIn = React.lazy(() => import('../Pages/Website/SignIn/SignIn'));
    const MainPage = React.lazy(() =>
        import('../Pages/Website/MainPage/MainPage'));
    const About = React.lazy(() =>
        import('../Pages/Website/About/About'));
    const Projects = React.lazy(() =>
        import('../Pages/Website/Projects/Projects'));
    const ProjectDetail = React.lazy(() =>
        import('../Pages/Website/Projects/Components/ProjectDetail'));
    const ServicesList = React.lazy(() =>
        import('../Pages/Website/Services/ServicesList'));
    const ServiceDetail = React.lazy(() =>
        import('../Pages/Website/Services/ServiceDetail'));
    const Contact = React.lazy(() =>
        import('../Pages/Website/Contact/Contact'));
    const ElectricVehicle = React.lazy(() =>
        import('../Pages/Website/Vehicle/ElectricVehicle'));
    const Career = React.lazy(() =>
        import('../Pages/Website/Career/CareerList'));
    const Partnership = React.lazy(() =>
        import('../Pages/Website/Partnership/Partnership'));
    const Services = React.lazy(() =>
        import('../Pages/Website/Services/WhatWeDo'));
    const Clients = React.lazy(() =>
        import('../Pages/Website/Clients/Clients'));
    const Partners = React.lazy(() =>
        import('../Pages/Website/Partners/Partners'));
    const AuthWebsiteLayout = React.lazy(() =>
        import('../HOC/Layout/AuthWebsiteLayout'));
    const MainWebsiteLayout = React.lazy(() =>
        import('../HOC/Layout/MainWebsiteLayout'));


    return(
        <BrowserRouter>
            <React.Suspense fallback={
                <div className='changeRouteLoading'>
                    <LinearProgress style={{ width: '100%'}} />
                </div>
            }>
                <Switch>
                    <MainWebsiteLayout path='/' exact component={MainPage} />
                    <MainWebsiteLayout path='/about' component={About} />
                    <MainWebsiteLayout path='/partnership' component={Partnership} />
                    <MainWebsiteLayout path='/projects' component={Projects} />
                    <MainWebsiteLayout path='/projectDetail/:id' component={ProjectDetail} />
                    <MainWebsiteLayout path='/services' component={ServicesList} />
                    <MainWebsiteLayout path='/serviceDetail/:id' component={ServiceDetail} />
                    <MainWebsiteLayout path='/contact' component={Contact} />
                    <MainWebsiteLayout path='/electricVehicle' component={ElectricVehicle} />
                    <MainWebsiteLayout path='/career' component={Career} />
                    <MainWebsiteLayout path='/clients' component={Clients} />
                    <MainWebsiteLayout path='/partners' component={Partners} />
                    <MainWebsiteLayout path='/what-we-do' component={Services} />
                    <AuthWebsiteLayout path='/signIn' component={SignIn} />
                    <MainWebsiteLayout component={NotFound} />
                </Switch>
            </React.Suspense>
        </BrowserRouter>
    )
}

export default Router;
