

export const SIGNIN_ACTION = 'SIGNIN_ACTION';
export const SIGNIN_SUCCESS_ACTION = 'SIGNIN_SUCCESS_ACTION';
export const SIGNIN_FAILED_ACTION = 'SIGNIN_FAILED_ACTION';




export const SIGNUP_ACTION = 'SIGNUP_ACTION';
export const SIGNUP_SUCCESS_ACTION = 'SIGNUP_SUCCESS_ACTION';
export const SIGNUP_FAILED_ACTION = 'SIGNUP_FAILED_ACTION';