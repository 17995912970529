import Routes from './Routes/Routes';
import { Provider } from 'react-redux';
import createStore from './Store/Store';
import {loadState, saveState} from "./Utils/localStorage";
import { Global, css } from '@emotion/react';
import * as colors from './Constants/Colors/Colors';
import 'mapbox-gl/dist/mapbox-gl.css';

// import font1 from '../src/Assets/fonts/Abel-Regular.ttf';
import './App.css'
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const persistedState = loadState();
const store = createStore(persistedState);

store.subscribe(() => {
    saveState(store.getState())
});

////////////////////// Global Styling ////////////////////////
const globalStyles = () => css `
  .websiteSimpleText{
    font-size: 20px !important;
    line-height: 32px !important;
    font-weight: 100;
    color: ${colors.textColorOnWhite} !important;
  }
  .relativePosition{
    position: relative;
  }
  //Data Table Styles
  .dataTableStyle {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;
    & > div{
      transition-duration: 0.3s;
    }
    & > div:hover{
      background-color: ${colors.state200} !important;
    }
  }
  .MuiAccordion-root:before{
    background-color: transparent;
  }
  .airBnbSlider{
    .MuiSlider-track{
      color: transparent !important;
    }
    .MuiSlider-thumb.Mui-focusVisible, .MuiSlider-thumb:hover{
      box-shadow: none !important;
    }
  }
  .MuiTooltip-tooltip{
    background-color: #000 !important;
    border-radius: 5px !important;
    color: ${colors.light3OnBlack} !important;
    font-size: 11px !important;
    padding: 8px 10px !important;
  }
  .inRowFlexItemsStartAlign{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
  .inRowFlexItemsSpaceBetweenAlignStart{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    @media(max-width: 768px){
      flex-direction: column;
    }
  }
  .dataTableHeader{
    width: 100%;
    padding: 0 10px !important;
    list-style: none;
    border-bottom: 2px solid ${colors.middleSilver};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media (max-width: 768px) {
      flex-direction: column;
      background-color: ${colors.state300};
    }
  }
  .websitePageContentFrame{
    position: relative;
    padding-top: 50px;
  }
  .dataTableContentRow{
    display: flex;
    flex-direction: row;
    list-style: none;
    justify-content: space-between;
    padding: 15px 5px;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
  .MuiPaper-elevation1{
    box-shadow: none !important;
  }
  .MuiAccordion-root:before{
    background-color: transparent !important;
  }
  .simpleTextStyle{
    font-size: 18px;
    line-height: 34px;
    color: ${colors.textColorOnWhite};
    text-align: justify;
  }
  .changeRouteLoading{
    position: relative;
    width: 100%;
    height: 3px;
    overflow: hidden;
    top: 0;
    left: 0;
    //background-color: #ffffffa3;
    //display: flex;
    //justify-content: center;
    //align-items: center;
  }
  .contentFrame{
    padding: 30px 7%;
    @media(max-width: 768px){
      padding: 5px 25px;
    }
  }
`


function App() {
  return (
    <Provider store={store}>
        <Global styles={globalStyles} />
      <Routes />
    </Provider>
  );
}

export default App;
