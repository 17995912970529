/** @jsxRuntime classic /
 /* @jsx jsx */
import {css, jsx} from '@emotion/react';


const NotFound = () => {
    return(
        <div style={{ minHeight: 450, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <h3>Not Found</h3>
        </div>
    )
}

export default NotFound;